@import "./Mixins.scss";
.user {
  margin: 40px 0;

  &__box {
    @include white-shadow();
    position: relative;
    padding: 40px 80px;
    &--margin {
      margin-top: 40px;
    }
  }
  &__title {
    @include green-title();
    margin: 0;
  }
  &__grid {
    margin: 40px auto;

    &--fixed {
      width: 90%;
    }
    &--two {
      @include grid(repeat(2, 1fr), 30px);
    }
    &--three {
      @include grid(repeat(3, 1fr), 30px);
    }
    &--four {
      @include grid(repeat(4, 1fr), 30px);
    }
  }
  &__label {
    @include general-label(var(--primary-bg));
  }
  &__input,
  &__select {
    @include general-input();
    &:disabled {
      pointer-events: none;
    }
  }
  &__cards {
    margin: 50px 0 20px;
    border-top: 1px solid var(--main-green);
  }
  &__top {
    @include flex(space-between);
    margin: 30px 0;
  }
  &__new {
    @include secondary-button();
  }
  &__icon {
    width: 20px;
    margin-right: 5px;
    &--actions {
      margin: 0;
      height: 23px;
    }
  }
  &__item {
    &[data-disabled="true"] {
      filter: blur(1px);
      cursor: progress;
    }
    &--expires {
      @include flex(space-between);
    }
    &--center {
      @include flex(center);
    }
  }
  &__expires {
    width: 45%;
  }
  &__holder {
    @include flex(center);
  }
  &__edit {
    margin-left: 30px;
  }
  &__actions {
    text-align: right;
    width: 90%;
    margin: 30px auto;
  }
  &__button {
    &:disabled {
      filter: grayscale(1);
    }
    &--cancel {
      @include secondary-button();
      margin-right: 15px;
    }
    &--save {
      @include primary-button();
    }
    &--icon {
      text-align: center;
      background-color: var(--primary-button);
      width: 45px;
      height: 45px;
      border-radius: 30px;
      margin: 0 10px;
    }
  }
  &__paragraph {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 19px;
  }
  &__transactions {
    text-align: right;
    margin: 30px auto;
    width: 90%;
  }
  &__round {
    @include flex(center);
    flex-direction: column;
    width: 135px;
    height: 135px;
    background: var(--primary-bg);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 65.5px;
  }
  &__name {
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 16px;
    text-align: center;
  }
  &__logo {
    width: 50px;
  }
}
.card {
  background: linear-gradient(180deg, #197bbd 0%, #3391d0 100%);
  padding: 20px;
  border-radius: 12px;
  color: white;
  min-width: 200px;
  &__name,
  &__number {
    font-weight: 500;
    font-size: 1.3rem;
    line-height: 14px;
    margin-bottom: 15px;
    &--expiration {
      margin-bottom: 0;
    }
  }
  &__expires {
    font-size: 0.9rem;
  }
}
