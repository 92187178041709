@font-face {
  font-family: "Nunito";
  src: url("../assets/fonts/Nunito-Light.ttf");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("../assets/fonts/Nunito-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Nunito";
  src: url("../assets/fonts/Nunito-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  src: url("../assets/fonts/Nunito-Bold.ttf");
  font-weight: 700;
  font-display: swap;
}

* {
  font-family: "Nunito", sans-serif;
}
