.pure-modal .panel-body {
  background-color: var(--primary-bg);
}
.pure-modal-backdrop .pure-modal {
  width: 95%;
  max-width: 1300px;
  margin: auto;
}
.modal {
  &__container {
    width: 100%;
    max-height: 80vh;
    overflow: scroll;
  }
  &__containerPDF {
    width: 100%;
    max-height: 80vh;
    overflow: hidden;
  }
  &__image{
      width: 100%;
  }
}

.pure-modal {
  & .panel-body {
    border-radius: 27px;
  }
}