@import "./Mixins.scss";
/* General styles for blocks */
* {
  box-sizing: border-box;
  outline: none;
}
:root {
  --status-pending: #F3BB1C;
  --status-approved: #3CC13B;
  --status-rejected: #E75656;
  --status-refounded: var(--status-rejected);
  --status-expired: gray;
}
html {
  font-size: 10px;
}
label {
  display: block;
}
body {
  @include center-bg();
  background-image: var(--general-bg);
  transition: all 0.5s ease;
  background-attachment: fixed;
}
input,
select,
textarea,
button {
  background-color: transparent;
  border: 0;
}
button {
  cursor: pointer;
  transition: all 0.1s linear;
}
button:active {
  transform: translateY(4px);
}
option {
  background-color: var(--table-cell-bg);
}

/* Theme Colors */
:root[data-theme="theme-light"] {
  --general-bg: url("../assets/images/moises-bg-white.png");
  --logo-title: url("../assets/images/logo-black.svg");
  --sun-icon: url("../assets/images/icons/sun-light.svg");
  --moon-icon: url("../assets/images/icons/moon-light.svg");
  --search-icon: url("../assets/images/icons/search-icon-advanced.svg");
  --select-icon: url("../assets/images/icons/icon-select.svg");
  --sidebar-bg: #3d5867;
  --main-green: #1eb0a9;
  --theme-bg: #ffffff;
  --text-color: #666666;
  --primary-gray: #969491;
  --title-color: rgba(109, 109, 109, 0.12);
  --primary-button: #3d5867;
  --primary-bg: #ffffff;
  --button-disabled: #d9d9d9;
  --table-cell-bg: #f9f9f9;
  --table-cell-line: #e7e7e7;
  --second-bg: #f7f8fa;
  --transparent-bg: rgba(247, 248, 250, .5);
  --compliance-user-bg: #fefefe;
  --secondary-text-color: #8d8b88;
  --dropdown-text-color: #070707;
  --dropdown-bg: #f8f8f8;
  --dropdown-table-row-bg: #f8f8f8;
  --dropdown-table-color: #6e6e6e;
  --dropdown-status-pending: #FCEEC6;
  --dropdown-status-rejected: #FBCDCD;
  --dropdown-status-approved: #CEEFCE;
  --dropdown-status-default: #a09e9a;
  --dropdown-input: #f4f4f4;
  --dropdown-input-hover: rgba(61, 88, 103, 0.301);
  --paysys-button: #3D5867;
  --paysys-button-active: #263740;
  --select-color: #A19E9E;
  --select-options: #FFFFFF;
  --select-text: #FFFFFF;
  --select-option: #BDBDBD;
  --psp-configuration-option: #FFFFFF;
  --psp-configuration-summary: #F1F1F1;
  --psp-configuration-border: #BDBDBD;
  --psp-configuration-name: #474747;
  --psp-configuration-input: #9D9D9D;
  --psp-configuration-label: #FFFFFF;
  --psp-configuration-label-text: #559FA9;
  --psp-configuration-label-border: #999795;
  --advanced-scroll: #E8E5E5;
  --advanced-scroll-thumb: #C9C8C6;
  --toggle-button: #3D5867;
  --toggle-button-border: #3D5867;
  --toggle-button-border-off: #E1E1E1;
  --toggle-button-circle: #FFFFFF;
  --toggle-button-circle-off: #E1E1E1;
}

:root[data-theme="theme-dark"] {
  --general-bg: url("../assets/images/moises-bg.png");
  --logo-title: url("../assets/images/logo-white.svg");
  --sun-icon: url("../assets/images/icons/sun-dark.svg");
  --moon-icon: url("../assets/images/icons/moon-dark.svg");
  --search-icon: url("../assets/images/icons/search-icon-advanced-white.svg");
  --select-icon: url("../assets/images/icons/icon-select-white.svg");
  --sidebar-bg: #052032;
  --main-green: #28343f;
  --theme-bg: #225b66;
  --text-color: #ffffff;
  --primary-gray: #ffffff;
  --title-color: rgba(255, 255, 255, 0.14);
  --primary-button: #225b66;
  --primary-bg: #28343f;
  --button-disabled: #475561;
  --table-cell-bg: #333f49;
  --table-cell-line: #465765;
  --second-bg: #333f49;
  --transparent-bg: rgba(51, 63, 73, .5);
  --compliance-user-bg: #333f49;
  --secondary-text-color: #ffffff;
  --dropdown-text-color: #ffffff;
  --dropdown-bg: #1c2c39;
  --dropdown-table-row-bg: #394953;
  --dropdown-table-color: #ffffff;
  --dropdown-status-pending: #f3bb1c;
  --dropdown-status-rejected: #f03738;
  --dropdown-status-approved: #3cc13b;
  --dropdown-status-default: #a09e9a;
  --dropdown-input: transparent;
  --dropdown-input-hover: rgba(65, 209, 228, 0.301);
  --paysys-button: #225B66;
  --paysys-button-active: #18373D;
  --select-color: #FFFFFF;
  --select-options: #28343F;
  --select-text: #333F49;
  --select-option: #3E4E5D;
  --psp-configuration-option: #333F49;
  --psp-configuration-summary: #333F49;
  --psp-configuration-border: #1D9D98;
  --psp-configuration-name: #FFFFFF;
  --psp-configuration-input: #FFFFFF;
  --psp-configuration-label: #333F49;
  --psp-configuration-label-text: #FFFFFF;
  --psp-configuration-label-border: #FFFFFF;
  --advanced-scroll: #131C27;
  --advanced-scroll-thumb: #6D7E8D;
  --toggle-button: #1EB0A9;
  --toggle-button-border: #1EB0A9;
  --toggle-button-border-off: #C5C5C5;
  --toggle-button-circle: #FFFFFF;
  --toggle-button-circle-off: #FFFFFF;

  #pagination-first-page,
  #pagination-previous-page,
  #pagination-next-page,
  #pagination-last-page,
  .amenitiesInfo span img,
  .showFilters img,
  .filterTitle img {
    filter: invert(1);
  }
}
/* General Container */
:root {
  --primary-green: #1eb0a9;
}
.general {
  padding: 30px 30px 30px 250px;
  color: var(--text-color);
}

/* Switches */

.switch {
  $root: &;
  margin: 10px 0;
  &__check {
    position: absolute;
    opacity: 0;
    + #{$root}__label {
      position: relative;
      cursor: pointer;
      padding: 0;
      display: inline-block;
    }
    &:checked {
      + #{$root}__label:before {
        background: var(--primary-green);
      }
      + #{$root}__label {
        span {
          left: 12px;
        }
      }
    }
  }
  &__aux {
    display: block;
    position: absolute;
    top: 3px;
    left: 1px;
    width: 10px;
    height: 10px;
    background: #ffffff;
    border-radius: 100px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
    transition: all 0.2s linear;
    &--margin {
      top: 8px;
    }
  }
  &__label {
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 15px;

    &:before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      vertical-align: middle;
      width: 25px;
      height: 16px;
      background: rgba(0, 0, 0, 0.1);
      transition: all 0.2s linear;
      border-radius: 11px;
    }
  }
}
/* Tabs */
.tabs {
  text-align: right;
  padding: 0 40px;
  position: relative;
  z-index: 0;
  &__switch {
    border-radius: 13px 13px 0px 0px;
    background-color: transparent;
    padding: 20px 20px;
    margin-left: 15px;
    min-width: 170px;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.12em;
    color: var(--primary-green);
    border-left: 1px solid var(--primary-green);
    border-top: 1px solid var(--primary-green);
    border-right: 1px solid var(--primary-green);

    &--active {
      background-color: var(--primary-bg);
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);
      border: 1px solid var(--primary-bg);
    }
  }
}

/* Hidden Parts */
.hidden {
  @include permission-block();
  &--upper {
    @include white-shadow();
  }
  &__title {
    @include green-title();
    &--no-margin{
      margin-bottom: 0;
      text-align: center;
    }
    &--margin {
      margin-top: 30px;
    }
  }
  &__grid {
    width: 90%;
    margin: 30px auto;
    &--three {
      @include grid(repeat(3, 1fr), 30px);
    }
    &--four {
      @include grid(repeat(4, 1fr), 30px);
    }
    &--five {
      @include grid(repeat(5, 1fr), 30px);
    }
    &--six {
      @include grid(repeat(4, 1fr), 30px);
    }
  }
  &__label {
    @include general-label(var(--second-bg));
    padding: 0 5px;
    &--page {
      display: block;
      text-align: left;
      margin: 0;
    }
    &--upper {
      background-color: var(--primary-bg);
    }
  }
  &__input,
  &__select {
    @include general-input();
    -webkit-appearance: none;
  }
  &__actions {
    text-align: right;
    width: 90%;
    margin: 30px auto;
  }
  &__button {
    &--cancel {
      @include secondary-button();
    }
    &--save {
      @include primary-button();
      margin-left: 15px;
    }
  }
  &__item {
    &--page {
      text-align: center;
    }
    &--phone {
      @include flex(space-between);
    }
    &--small {
      max-width: 280px;
    }
  }
  &__phone {
    width: 77%;
    &--code {
      width: 20%;
    }
  }
  &__box {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    padding: 20px 30px;
  }
  &__subtitle {
    font-weight: 800;
    font-size: 1.6rem;
    line-height: 22px;
    margin-bottom: 20px;
    &--inline {
      display: inline-block;
      margin: 0;
    }
  }
  &__permission {
    max-height: 200px;
    overflow-y: auto;
  }
  &__selector {
    @include flex(center);
    display: inline-flex;
    margin: 5px 20px;
  }
  &__add {
    margin: 30px 0;
  }
  &__ip {
    @include secondary-button();
  }
}

/* Filter for search TP ID */
.search {
  @include flex(flex-end);
  margin: 40px 0;
  &__input {
    background-color: var(--primary-bg);
    height: 33px;
    width: 50%;
    max-width: 500px;
    border-radius: 24px;
    padding: 0 20px;
    color: var(--text-color);
  }
  &__button {
    background-color: var(--primary-button);
    padding: 5px 10px;
    border-radius: 45px;
    margin-left: -25px;
  }
  &__icon {
    width: 20px;
  }
}
/* Loading */
.loading {
  // @include center-bg();
  // background-image: var(--logo-title);
  // background-size: 200px;
  height: 100vh;
  width: 100%;
  @include flex(center);
  // animation: pulse 3s infinite;
  &--table {
    height: 100%;
    min-height: 300px;
    background-color: var(--second-bg);
  }
  &__data {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  &__data div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: var(--text-color);
    animation: loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  &__data div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  &__data div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  &__data div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
}

.swal__link{
  @include flex(center);
  flex-wrap: wrap;
  & a {
    @include secondary-button();
    text-decoration: none;
    margin: 5px;
  }
}
.tpid{
  &__title{
    text-align: center;
    text-transform: uppercase;
    margin-top: 35px;
    font-size: 2rem;
    &--light{
      font-weight: 300;
    }
  }
  &__actions{
    @include flex(center);
    margin: 35px 0 20px;
  }
}
@keyframes loading {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
@include media(xl) {
  .general {
    padding: 15px;
  }
}
@include media(md) {
  .tabs {
    padding: 0 25px;
    &__switch {
      min-width: 120px;
      font-size: 1.2rem;
    }
  }
  .hidden {
    &__grid {
      width: 96%;

      &--three {
        grid-template-columns: 1fr 1fr;
      }
      &--four {
        grid-template-columns: 1fr 1fr;
      }
      &--five {
        grid-template-columns: 1fr 1fr 1fr;
      }
      &--six {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    &__button {
      &--cancel {
        margin-bottom: 10px;
      }
    }
    &__item {
      &--empty {
        display: none;
      }
    }
  }
}

@include media(sm) {
  .tabs {
    @include flex(center);
    flex-wrap: wrap;
    &__switch {
      border: 1px solid var(--primary-green);
      border-radius: 13px;
      margin: 10px;
    }
  }
  .hidden {
    &__grid {
      width: 98%;

      &--three {
        grid-template-columns: 1fr;
      }
      &--four {
        grid-template-columns: 1fr;
      }
      &--five {
        grid-template-columns: 1fr;
      }
      &--six {
        grid-template-columns: 1fr;
      }
    }
  }
}
