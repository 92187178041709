.theme {
  position: relative;
  display: block;
  width: 70px;
  height: 28px;
  margin: auto;
  padding: 0;
  background-color: var(--main-green);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 28px;
  &:active {
    transform: translateY(0);
  }
  &::before,
  &::after {
    position: absolute;
    width: 15px;
    height: 15px;
    top: 14px;
    transform: translateY(-50%);
    transition: all .5s ease;
    will-change: auto;
    z-index: 0;
  }
  &::before {
    content: var(--sun-icon);
    left: 10px;
  }
  &::after {
    content: var(--moon-icon);
    right: 10px;
  }
  &__slider {
    position: relative;
    display: block;
    width: 31px;
    height: 18px;
    margin: 0;
    margin-left: 5px;
    padding: 2px;
    background-color: var(--theme-bg);
    border-radius: 18px;
    transition: transform .5s ease;
    will-change: transform;
    z-index: 1;
    &[data-dark="true"] {
      transform: translateX(29px);
    }
  }
  &__icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}