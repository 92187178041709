@import "../Mixins.scss";

.header {
  @include flex(space-between);
  &__title {
    font-weight: bold;
    font-size: 5rem;
    line-height: 68px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: var(--title-color);
    white-space: pre-wrap;
  }
  &__logo-link {
    @include center-bg();
    display: block;
    width: 140px;
    height: 120px;
    background-image: var(--logo-title);
    background-size: contain;
  }
  &__mobile {
    @include flex(space-between);
    position: absolute;
    display: none;
    width: 100%;
    padding: 15px 20px;
    background-color: var(--sidebar-bg);
    top: 0;
    left: 0;
    z-index: 10;
  }
  &__logo {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
  &__user {
    @include flex(center);
  }
  &__user-name {
    margin-top: 15px;
    margin-right: 20px;
    color: white;
    font-size: 1.2rem;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    line-height: 20px;
  }
  &__user-picture {
    display: block;
    width: 50px;
    height: 50px;
    margin: auto;
  }
  &__user-picture-content {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  &__actions {
    @include flex(center);
  }
  &__button {
    @include primary-button();
    margin-left: 20px;
  }
}

@import "./responsive/Header";
