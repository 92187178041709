$breakpoint-xs: 400px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1075px;
$breakpoint-xxl: 1200px;

/* Utilities */
@mixin media($media) {
  @if $media == xs {
    @media (max-width: $breakpoint-xs) {
      @content;
    }
  } @else if $media == sm {
    @media (max-width: $breakpoint-sm) {
      @content;
    }
  } @else if $media == md {
    @media (max-width: $breakpoint-md) {
      @content;
    }
  } @else if $media == lg {
    @media (max-width: $breakpoint-lg) {
      @content;
    }
  } @else if $media == xl {
    @media (max-width: $breakpoint-xl) {
      @content;
    }
  } @else if $media == xxl {
    @media (max-width: $breakpoint-xxl) {
      @content;
    }
  }
}
@mixin flex($justify) {
  display: flex;
  justify-content: $justify;
  align-items: center;
}
@mixin grid($columns, $gap) {
  display: grid;
  grid-template-columns: ($columns);
  grid-gap: ($gap);
}
@mixin center-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/* Styles */
@mixin blue-gradient() {
  background: linear-gradient(
    180deg,
    rgba(0, 41, 65, 0.5135) 30.26%,
    rgba(31, 69, 86, 0.6557) 100%
  );
  box-shadow: -1px 1px 40px rgba(0, 0, 0, 0.31);
  border-radius: 46px;
}
@mixin primary-button() {
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  background-color: var(--primary-button);
  border-radius: 21px;
  padding: 10px 30px;
  &:hover {
    background-color: var(--primary-green);
  }
  &--disabled {
    background-color: var(--button-disabled);
  }
}
@mixin secondary-button() {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 18px;
  text-align: center;
  color: #ffffff;
  background-color: transparent;
  border-radius: 21px;
  padding: 10px 30px;
  border: 1px solid var(--primary-green);
  color: var(--primary-green);

  &:hover {
    background-color: var(--primary-green);
    color: #ffffff;
  }
  &--disabled {
    filter: grayscale(1);
    // background-color: var(--button-disabled);
  }
}
@mixin general-label($bg) {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 14px;
  background-color: ($bg);
  display: inline-block;
  padding: 2px 10px;
  margin-left: 10px;
  position: relative;
}
@mixin general-input() {
  border: 1px solid #8d8b88;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  margin-top: -10px;
  padding: 10px 20px 10px;
  color: var(--text-color);
}
@mixin permission-block() {
  width: 100%;
  margin: 30px auto;
  background-color: var(--second-bg);
  padding: 50px;
}
@mixin green-title() {
  font-weight: bold;
  font-size: 2rem;
  line-height: 27px;
  letter-spacing: 0.08em;
  color: var(--primary-green);
  margin-bottom: 60px;
}
@mixin subtitle() {
  font-weight: normal;
  font-size: 2.6rem;
  line-height: 35px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: var(--primary-gray);
}
@mixin white-shadow() {
  background-color: var(--primary-bg);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
}
