
.dashboard {
  @include media(lg) {
    &__grid {
      grid-template-columns: 1fr 1fr;
    }
    &__graph {
      padding: 0;
    }
  }
  @include media(sm) {
    &__grid {
      grid-template-columns: 1fr;
    }
  }
}