@import "../Mixins.scss";

.advanced {
  position: relative;
  width: 100%;
  padding: 20px 20px;
  min-height: calc(100vh - 327px);
  background-color: var(--primary-bg);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  &__search {
    @include flex(space-between);
    width: 100%;
    padding: 0 20px;
  }
  &__search-text {
    margin: 10px 0;
    font-size: 26px;
    line-height: 35px;
    letter-spacing: 0.09em;
    text-transform: uppercase;
  }
  &__search-title {
    display: block;
    font-weight: 600;
    font-size: 9px;
    line-height: 12px;
    text-transform: uppercase
  }
  &__search-input-container {
    position: relative;
    width: 240px;
    height: 32px;
    margin-left: 20px;
    &::after {
      position: absolute;
      width: 20px;
      height: 20px;
      content: var(--search-icon);
      top: 7px;
      right: 8px;
    }
  }
  &__search-input {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    border: 1px solid #BFBFBF;
    color: var(--text-color);
    border-radius: 20px;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    &::placeholder {
      font-weight: 300;
      font-size: 12px;
      line-height: 16px;
      color: var(--secondary-text-color);
    }
  }
  &__content {
    width: 100%;
    height: calc(100vh - 546px);
    margin: 20px 0;
    padding: 0 20px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 10px;
      background-color: var(--advanced-scroll);
      border-radius: 16px;
    }
    &::-webkit-scrollbar-thumb {
      width: 10px;
      background-color: var(--advanced-scroll-thumb);
      border-radius: 16px;
    }
  }
  &__actions {
    @include flex(flex-end);
    width: 100%;
    padding: 0 20px;
  }
  &__action {
    width: 130px;
    padding: 8px;
    margin-left: 20px;
    background-color: var(--paysys-button);
    border-radius: 22px;
    border: 1px solid transparent;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    &--secondary {
      background-color: transparent;
      border: 1px solid #559FA9;
      color: #559FA9;
    }
    &:disabled {
      background-color: var(--button-disabled);
    }
  }
}

@import "./responsive/Advanced";