@import "../Mixins.scss";

.sidebar {
  position: fixed;
  max-width: 220px;
  width: 100%;
  height: 100vh;
  padding: 40px 5px;
  background-color: var(--sidebar-bg);
  color: #ffffff;
  text-align: center;
  box-shadow: 4px -1px 18px rgba(0, 0, 0, 0.25);
  border-top-right-radius: 43px;
  border-bottom-right-radius: 43px;
  top: 0;
  left: 0;
  &__profile {
    position: relative;
    display: inline-block;
    padding: 5px;
    border: 1px solid #26dfd6;
    border-radius: 100px;
  }
  &__picture-container {
    display: block;
    height: 80px;
    width: 80px;
    margin: auto;
  }
  &__picture {
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  &__settings {
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    bottom: -5px;
    right: -15px;
  }
  &__settings-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__name {
    margin-top: 15px;
    font-size: 1.2rem;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    line-height: 20px;
  }
  &__position {
    margin: 7px 0;
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 15px;
  }
  &__list {
    padding: 0 20px;
    margin: 40px 0;
    text-align: left;
    list-style: none;
  }
  &__item {
    margin: 20px 0;
  }
  &__isologo {
    width: 35px;
    margin-right: 20px;
  }
}
.dock {
  @include flex(space-around);
  position: fixed;
  display: none;
  width: 100%;
  padding: 16px 20px;
  background-color: var(--sidebar-bg);
  box-shadow: 2px -4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 40px 40px 0 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  &__button {
    width: 20px;
    height: 20px;
    padding: 0;
    &:last-child {
      width: 24px;
      height: 24px;
    }
  }
  &__button-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.mobile {
  position: fixed;
  width: 100%;
  height: 100vh;
  padding: 40px 0;
  background-color: var(--sidebar-bg);
  bottom: 0;
  z-index: 20;
  &__list {
    @include grid(repeat(2, 1fr), 40px 10px);
    width: 100%;
    margin: 60px 0;
    padding: 0;
    list-style: none;
  }
  &__isologo {
    width: 90px;
    margin-bottom: 15px;
  }
  &__close {
    position: absolute;
    width: 20px;
    height: 20px;
    padding: 0;
    top: 20px;
    right: 20px;
  }
  &__close-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@import "./responsive/Sidebar";