@import "../../styles/Mixins.scss";

.loader {
  @include flex(center);
  position: fixed;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, .4);
  top: 0;
  left: 0;
  z-index: 1000;
  &__container {
    position: absolute;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
  }
  &__spinner {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
    &:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid #FFF;
      border-color: #FFF transparent #FFF transparent;
      animation: loader-animation 1.2s linear infinite;
    }
  }
  &__text {
    font-size: 2rem;
    color: #FFFFFF;
  }
}

@keyframes loader-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
