@import "../Mixins.scss";

.dashboard {
  width: 90%;
  margin: 50px auto 50px;
  &__grid {
    @include grid( repeat(3, 1fr),30px);
  }
  &__box {
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    padding: 20px 30px;
    position: relative;
    z-index: 2;
  }
  &__graph{
    padding: 10px 40px;
  }
}


@import "./responsive/Dashboard";
