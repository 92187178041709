.advanced {
  @include media(xl) {
    margin-bottom: 80px;
  }
  @include media(md) {
    &__search,
    &__actions {
      padding: 0;
    }
    &__content {
      padding: 20px 10px;
    }
  }
  @include media(xs) {
    &__search {
      flex-direction: column;
    }
    &__search-input-container {
      margin-left: 0;
    }
  }
}