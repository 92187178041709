@import "./Mixins.scss";
.settings {
  &__container {
    @include grid(repeat(4, 1fr), 30px);
    margin: 100px 0;
  }
  &__box {
    @include white-shadow();
    padding: 30px 30px;
    &--picture {
      text-align: center;
    }
  }
  &__username {
    font-weight: 800;
    font-size: 2rem;
    line-height: 2.7rem;
    text-transform: uppercase;
    margin: 0;
  }
  &__position {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.9rem;
    margin: 0 0 40px;
  }
  &__picture {
    width: 80%;
    height: 80%;
    max-width: 220px;
    max-height: 220px;
    margin: auto;
    position: relative;
  }
  &__user-image {
    width: 100%;
    height: 100%;
    border-radius: 500px;
    object-fit: cover;
  }
  &__user-input {
    opacity: 0;
    position: absolute;
  }
  &__upload-button {
    background-color: var(--primary-button);
    width: 40px;
    height: 40px;
    border-radius: 100px;
    position: absolute;
    top: 10px;
    right: 5px;
  }
  &__upload-icon {
    width: 25px;
  }
  &__box-title {
    @include green-title();
    text-align: left;
    margin: 0 0 40px;
  }
  &__data {
    min-height: 230px;
  }
  &__item {
    margin: 40px 0;
    &--actions {
      @include flex(space-between);
    }
  }
  &__action {
    @include primary-button();
    &--cancel {
      @include secondary-button();
    }
  }
  &__label {
    @include general-label(var(--second-bg));
    padding: 0 5px;
    &--page {
      display: block;
      text-align: left;
      margin: 0;
    }
    &--upper {
      background-color: var(--primary-bg);
    }
  }
  &__input,
  &__select {
    @include general-input();
    -webkit-appearance: none;
  }
  &__qr {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    display: block;
    min-height: 200px;
    background-color: #ededed;
  }
  &__secret {
    font-size: 1rem;
    text-align: center;
    letter-spacing: 2px;
    word-break: break-word;
  }
  &__text {
    font-size: 1.5rem;
  }
  @include media(xxl) {
    &__container {
      grid-template-columns: 1fr 1fr;
    }
  }
  @include media(sm) {
    &__container {
      grid-template-columns: 1fr;
    }
  }
}
