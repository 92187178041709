@import "../Mixins.scss";

$icon-select: var(--select-icon);

.select {
  $root: &;
  position: relative;
  width: 100%;
  max-width: 270px;
  height: 40px;
  border: 1px solid var(--select-color);
  border-radius: 5px;
  &[data-readonly="true"] {
    border-color: transparent;
    pointer-events: none;
    &::after {
      display: none;
    }
  }
  &[data-active="true"] {
    border-radius: 5px 5px 0 0;
    #{$root}__options {
      display: block;
    }
    &::after {
      transform: rotateX(180deg);
    }
  }
  &::before {
    position: absolute;
    display: block;
    min-width: max-content;
    padding: 2px 4px;
    content: attr(data-text);
    background-color: var(--select-text);
    color: var(--select-color);
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 16px;
    top: -11px;
    left: 7px;
  }
  &::after {
    @include center-bg();
    position: absolute;
    width: 12px;
    height: 12px;
    content: "";
    background-image: $icon-select;
    background-size: contain;
    transition: transform .3s linear;
    will-change: transform;
    top: calc(50% - 6px);
    right: 10px;
  }
  &:hover {
    cursor: pointer;
  }
  &__value,
  &__option {
    margin: 0;
    padding: 8px;
    text-align: start;
    color: var(--select-color);
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 20px;
  }
  &__value {
    border: 0.5px solid transparent;
    pointer-events: none;
  }
  &__options {
    position: absolute;
    display: none;
    width: 100%;
    max-height: 135px;
    background-color: var(--select-options);
    border: 1px solid var(--select-color);
    border-radius: 0 0 15px 15px;
    overflow-y: auto;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
    &::-webkit-scrollbar {
      width: 5px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      width: 5px;
      background-color: var(--select-option);
      border-radius: 6px;
    }
  }
  &__option {
    display: block;
    transition: background-color .4s ease;
    will-change: background-color;
    &:hover {
      background-color: var(--select-option);
      color: #FFFFFF;
    }
  }
}