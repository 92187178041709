.login {
  @include media(sm) {
    &__container {
      width: 100%;
      min-height: 100vh;
      border-radius: 0;
    }
    &__submit {
      width: 100%;
    }
  }
  @include media(xs) {
    &__logo {
      height: 160px;
    }
  }
}