@import "../../styles/NewMixins";

.searchables {
  @include flex(center, 10px, stretch);
  width: 100%;
  max-width: 320px;
  margin-left: auto;
  flex-direction: column;
  &__box {
    @include flex(center, 10px);
    width: 100%;
    & > input {
      width: 100%;
    }
  }
  &__btn {
    @include btn-secondary;
    &--ok {
      @include btn-primary;
      max-width: 100px;
    }
  }
}