.menu-link {
  $rootMobile: &;
  @include media(lg) {
    &--empty {
      display: none;
    }
  }
  @include media(sm) {
    &[data-small="true"] {
      &::before,
      &::after {
        width: 72px;
        height: 72px;
      }
      #{$rootMobile}__icon {
        width: 80px;
        height: 80px;
        padding: 25px;
      }
      #{$rootMobile}__title {
        &::before {
          width: 58px;
          height: 58px;
          top: 10px;
        }
      }
    }
    &::before,
    &::after {
      border-width: 3px;
      width: 94px;
      height: 94px;
    }
    &__icon {
      width: 100px;
      height: 100px;
      padding: 30px;
    }
    &__title {
      &::before {
        width: 68px;
        height: 68px;
      }
    }
  }
  @include media(xs) {
    &__title {
      font-size: 1.4rem;
    }
  }
}