@use "sass:map";

@import "Vars";

@mixin for-size($size) {
  $media: map-get($devices, $size);

  @if $media {
    @media screen and (max-width: $media) {
      @content;
    }
  }
}
@mixin flex($justify, $gap: false, $align: center) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  @if $gap {
    gap: $gap;
  }
}
@mixin grid($columns, $gap) {
  display: grid;
  grid-template-columns: $columns;
  gap: $gap;
}
@mixin text($size, $weight: initial, $line-height: initial, $align: initial) {
  font-style: normal;
  font-size: $size;
  font-weight: $weight;
  line-height: $line-height;
  text-align: $align;
}
@mixin content($width: 1500px, $padding: 0 16px) {
  display: block;
  width: 100%;
  max-width: $width;
  margin: 0 auto;
  padding: $padding;
}
@mixin background-img($image, $color: false) {
  @if $color {
    background: $image, $color;
  } @else {
    background-image: $image;
  }
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@mixin btn-primary() {
  @include text(1.4rem, 600, 1.6rem, center);
  display: block;
  width: 100%;
  color: #ffffff;
  background-color: var(--primary-button);
  border: 1px solid var(--primary-button);
  border-radius: 20px;
  padding: 8px 15px;
  &:hover {
    background-color: var(--primary-green);
  }
  &--disabled {
    background-color: var(--button-disabled);
  }
}
@mixin btn-secondary() {
  @include text(1.4rem, 600, 1.6rem, center);
  display: block;
  width: 100%;
  color: #ffffff;
  background-color: transparent;
  border-radius: 20px;
  padding: 8px 15px;
  border: 1px solid var(--primary-green);
  color: var(--primary-green);
  &:hover:not(:disabled) {
    background-color: var(--primary-green);
    color: #ffffff;
  }
  &:disabled {
    filter: grayscale(.75);
    // pointer-events: none;
    cursor: not-allowed;
    transform: translateY(0);
  }
  &--disabled {
    filter: grayscale(1);
    // background-color: var(--button-disabled);
  }
}