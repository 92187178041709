.language {
  $root: &;
  @include media(sm) {
    &__selector {
      &--mobile {
        &:active {
          transform: translateY(0);
        }
        #{$root}__icon {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}