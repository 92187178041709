@import "./Mixins.scss";
.affico {
  $root: &;
  width: 100%;
  margin: 100px auto 50px;
  &__buttons {
    text-align: right;
    margin-bottom: 40px;
  }
  &__action {
    @include secondary-button();
    margin-left: 15px;
    margin-bottom: 10px;
  }
  &__input {
    display: none;
  }
  &__allowed {
    position: relative;
  }
  &__ip {
    @include general-input();
  }
  &__delete {
    position: absolute;
    top: 15px;
    right: 0;
    z-index: 2;
  }
  &__icon {
    width: 22px;
    margin-right: 10px;
  }
  &__massive {
    display: inline-block;
  }
}
.filters {
  margin: 30px;

  &__title {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 19px;
    color: var(--text-color);
    display: inline-block;
  }
  &__button {
    @include flex(center);
    display: inline-flex;
    background: #eaeaea;
    border: 1px solid #5a5a5a;
    border-radius: 17.5px;
    margin: 0 10px;
    padding: 10px 20px;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 16px;
    text-align: center;
  }
  &__icon {
    width: 15px;
    margin-left: 10px;
  }
}
.moveto {
  padding-top: 40px;
  &__submit {
    @include primary-button();
    display: block;
    margin-top: 20px;
    margin-left: auto;
  }
  &__move {
    display: block;
    margin: 0 auto;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--primary-button);
    & > img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
