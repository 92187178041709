@import "./Mixins.scss";
.compliance {
  margin: 70px 0;
  &__assign {
    box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    margin: auto;
  }
  &__assign-title {
    @include green-title();
    padding: 30px 30px 0;
    margin-bottom: 0;
  }
  &__assign-actions {
    @include flex(space-between);
    width: 95%;
    margin: 30px auto;
  }
  &__assign-button {
    &--cancel {
      @include secondary-button();
    }
    &--save {
      @include primary-button();
      margin-left: 15px;
    }
  }
  &__graphics {
    width: 100%;
    background-color: var(--primary-bg);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 35px;
    margin-top: 50px;
    padding: 16px;
    overflow-x: auto;
  }
  @include media(sm) {
    &__assign-actions {
      flex-direction: column;
    }
    &__assign-buttons {
      margin-top: 20px;
    }
  }
}
