@import "./Mixins.scss";
.table {
  @include white-shadow();
  margin-top: -5px;
  padding: 30px 0;
  position: relative;
  &--assign {
    border-radius: 0;
  }
  &--margin {
    margin-top: 40px;
  }
  &__checker {
    @include flex(center);
    position: relative;
    width: 70px;
    gap: 5px;
    flex-direction: column;
    z-index: 0;
    &:hover {
      cursor: pointer;
    }
    &::before {
      display: block;
      content: "";
      width: 100%;
      height: 20px;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 40px;
      transition: background-color .5s ease;
    }
    &::after {
      position: absolute;
      display: block;
      content: "";
      width: 24px;
      height: 14px;
      border-radius: 24px;
      background-color: #FFFFFF;
      box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.15);
      top: 3px;
      left: 3px;
      z-index: 0;
      transition: transform .5s ease;
    }
    &[data-checked="true"] {
      &::before {
        background-color: var(--primary-green);
      }
      &::after {
        transform: translateX(calc(64px - 100%));
      }
    }
  }
  &__checker-input {
    display: none;
  }
  &__checker-text {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.4rem;
    text-align: center;
  }
  .rdt_TableHeader,
  header,
  &__container {
    border-radius: 35px;
    background-color: var(--primary-bg);
    padding: 0;
  }
  .rdt_Pagination {
    border-bottom-left-radius: 35px;
    border-bottom-right-radius: 35px;
    background-color: var(--primary-bg);
    color: var(--text-color);
  }
  &__search {
    @include flex(center);
  }
  &__action {
    background-color: var(--primary-button);
    border-radius: 25px;
    width: 30px;
    height: 30px;
    margin: 0 10px;
    &--upper {
      width: 40px;
      height: 40px;
    }
  }
  &__icon {
    width: 11px;
    &--upper {
      width: 18px;
    }
  }
  &__move {
    display: block;
    width: 32px;
    height: 32px;
    padding: 4px;
    background-color: var(--primary-button);
    border-radius: 50%;
    &:active {
      transform: translateY(0) scale(.95);
    }
  }
  &__move-icon {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: contain;
  }
  &__image {
    width: 20px;
    margin-right: 5px;
    margin-top: -5px;
  }
  &__filter {
    border: 1px solid var(--primary-green);
    padding: 13px 30px;
    border-radius: 20px;

    color: var(--text-color);
    min-height: 40px;
  }
  &__header {
    text-align: right;
    max-width: 95%;
    margin: 30px auto;
    width: 100%;
  }
  &__title {
    font-weight: normal;
    font-size: 2.6rem;
    line-height: 35px;
    letter-spacing: 0.09em;
    text-transform: uppercase;
    color: var(--text-color);
  }
  &__add {
    @include secondary-button();
  }
  &__send {
    &:disabled {
      pointer-events: none;
      filter: grayscale(1);
    }
  }
  &__container {
    border: 1px solid var(--table-cell-line);
    border-radius: 13px;
    max-width: 95%;
    margin: auto;
  }
  &__initial {
    @include flex(space-between);
    padding: 0 30px;
  }
  &__button {
    color: var(--text-color);
    &--primary {
      @include primary-button();
      margin-right: 10px;
      min-height: 40px;
    }
    &:disabled {
      cursor: default;
      &:hover {
        cursor: default;
      }
      &:active {
        transform: translateY(0);
      }
    }
  }
  &__link{
    text-decoration: none;
    color: var(--text-color);
  }
  /* Table styles */
  .rdt_Pagination {
    border: 0;
    max-width: 95%;
    margin: 20px auto 10px;
  }

  .rdt_Table,
  .rdt_TableHeadRow,
  .rdt_TableCol,
  .rdt_TableRow,
  .rdt_TableCol_Sortable {
    background-color: var(--primary-bg);
    color: var(--text-color);
  }
  .rdt_TableCol_Sortable:hover {
    color: var(--primary-green);
  }
  .rdt_TableRow {
    &:nth-child(odd) {
      background-color: var(--table-cell-bg);
    }
  }
  .rdt_TableCol,
  .rdt_TableCell {
    border-left: 1px solid var(--table-cell-line);
    &:first-of-type {
      border-left: 0;
    }
  }
  .rdt_TableCell {
    border-top: 1px solid var(--table-cell-line);
  }
}
@include media(sm) {
  .table {
    &__initial {
      flex-direction: column;
    }
  }
}
