@import "../Mixins.scss";

.support {
  position: relative;
  width: 100%;
  padding: 20px 20px;
  min-height: calc(100vh - 327px);
  background-color: var(--primary-bg);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 35px;
  &__content {
    width: 100%;
    height: calc(100vh - 546px);
    margin: 20px 0;
    padding: 0 20px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 10px;
      background-color: var(--advanced-scroll);
      border-radius: 16px;
    }
    &::-webkit-scrollbar-thumb {
      width: 10px;
      background-color: var(--advanced-scroll-thumb);
      border-radius: 16px;
    }
  }
  &__actions {
    @include flex(flex-end);
    width: 100%;
    padding: 0 20px;
  }
  &__action {
    width: 130px;
    padding: 8px;
    margin-left: 20px;
    background-color: var(--paysys-button);
    border-radius: 22px;
    border: 1px solid transparent;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    &--secondary {
      background-color: transparent;
      border: 1px solid #559FA9;
      color: #559FA9;
    }
    &:disabled {
      background-color: var(--button-disabled);
    }
  }
}
.user__item {
  margin-bottom: 20px;
}