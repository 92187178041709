@import "./Mixins.scss";
.affiliate {
  margin: 80px 0;
  &__subtitle {
    @include subtitle();
  }
  &__box {
    @include white-shadow();
    padding: 40px;
    position: relative;
  }
  &__name {
    font-weight: bold;
    font-size: 2.1rem;
    line-height: 29px;
  }
  &__grid {
    width: 90%;
    margin: 30px auto;
    &--three {
      @include grid(repeat(3, 1fr), 30px);
    }
    &--four {
      @include grid(repeat(4, 1fr), 30px);
    }
    &--five {
      @include grid(repeat(5, 1fr), 30px);
    }
  }
  &__label {
    @include general-label(var(--primary-bg));
  }
  &__input,
  &__select {
    @include general-input();
  }
  &__heading {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 19px;
  }
  &__allowed {
    position: relative;
  }
  &__ip {
    @include general-input();
  }
  &__delete {
    position: absolute;
    top: 0;
    right: 5px;
    z-index: 2;
  }
  &__icon {
    width: 20px;
  }
  &__actions {
    text-align: right;
    width: 90%;
    margin: 30px auto;
  }
  &__button {
    &--cancel {
      @include secondary-button();
      margin-right: 15px;
    }
    &--save {
      @include primary-button();
    }
  }
  &__new {
    @include secondary-button();
  }
  &__pays {
    @include grid(1fr 1fr, 0);
    background-color: var(--table-cell-bg);
    padding: 20px;
    margin: 30px auto 70px;
    border-radius: 27px;
    border: 1px solid var(--primary-gray);
  }
  &__payment {
    @include flex(space-evenly);
    border-right: 1px solid var(--primary-green);
    &--last {
      border: 0;
    }
  }
  &__amount {
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 25px;
    color: var(--primary-green);
    padding: 10px 30px;
  }
  &__date {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 20px;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 30px;
  }
}
