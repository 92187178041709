@import "../Mixins.scss";

.welcome {
  $root: &;
  @include center-bg();
  @include grid(1fr 3fr, 30px);
  background-image: url("../../assets/images/moises-bg.png");
  width: 100%;
  min-height: 100vh;
  &__user {
    @include blue-gradient();
    @include flex(space-between);
    flex-direction: column;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    text-align: center;
    padding: 100px 10px;
  }
  &__mobile {
    display: none;
  }
  &__profile {
    border: 3px solid #225b67;
    border-radius: 100px;
    display: inline-block;
    padding: 10px;
    position: relative;
  }
  &__picture {
    display: block;
    height: 160px;
    width: 160px;
    margin: auto;
    border-radius: 50%;
    object-fit: cover;
  }
  &__settings {
    position: absolute;
    display: block;
    right: -3px;
    bottom: -5px;
  }
  &__icon {
    width: 30px;
  }
  &__name {
    font-weight: normal;
    font-size: 2rem;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #ffffff;
  }
  &__logout {
    display: block;
    background: rgba(45, 115, 125, 0.65);
    border-radius: 9px;
    font-weight: 800;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #ffffff;
    padding: 15px 40px;
  }
  &__hour {
    font-weight: 800;
    font-size: 4rem;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 5px;
  }
  &__day {
    color: #ffffff;
    font-size: 1.1rem;
  }
  &__principal {
    @include flex(space-between);
    flex-direction: column;
    padding: 50px 20px;
    text-align: center;
  }
  &__logo {
    max-width: 140px;
    width: 90%;
    height: 140px;
    margin-bottom: 30px;
    object-fit: contain;
  }
  &__boxes {
    @include grid(repeat(4, 1fr), 60px 30px);
    width: 90%;
    margin: auto;
  }
  &__mobile-menu {
    @include flex(space-evenly);
    position: fixed;
    display: none;
    width: 100%;
    height: 60px;
    background-color: #163646;
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
  &__mobile-link {
    display: block;
    padding: 0;
    width: 24px;
    height: 24px;
    &:last-child {
      width: 31px;
      height: 31px;
    }
    &:active {
      transform: translateY(0);
    }
  }
  &__mobile-link-icon {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@import "./responsive/Welcome";