@import "../../styles/NewMixins";

.affmove {
  @include flex(center);
  position: fixed;
  width: 100%;
  height: 100vh;
  padding: 20px;
  background-color: var(--transparent-bg);
  color: var(--text-color);
  inset: 0;
  z-index: 25;
  &__form {
    position: relative;
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background-color: var(--primary-bg);
    border-radius: 10px;
    z-index: 0;
  }
  &__close {
    position: absolute;
    display: block;
    width: 24px;
    height: 24px;
    padding: 6px;
    background-color: var(--sidebar-bg);
    border-radius: 50%;
    top: 5px;
    right: 5px;
    z-index: 0;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  &__title {
    @include text(2rem, 700, 2.2rem);
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
  }
  &__customer {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    background-color: var(--second-bg); 
    border-radius: 5px;
  }
  &__customer-box {
    @include flex(center, 6px, stretch);
    width: 100%;
    margin-bottom: 6px;
    flex-wrap: wrap;
  }
  &__customer-text {
    @include text(1.4rem, 700, 1.6rem);
    flex: 1;
    width: 100%;
    margin: 0;
    &[data-type="email"],
    &[data-type="name"] {
      min-width: max-content;
    }
    &[data-type="status"] {
      & > span {
        border: 1px solid transparent;
        border-radius: 0.5em;
        width: 100%;
        text-align: center;
        padding: 1px 0.3em;
      }
      &[data-status="pending"] > span {
        border-color: var(--status-pending);
        color: var(--status-pending);
      }
      &[data-status="approved"] > span {
        border-color: var(--status-approved);
        color: var(--status-approved);
      }
      &[data-status="rejected"] > span {
        border-color: var(--status-rejected);
        color: var(--status-rejected);
      }
      &[data-status="refounded"] > span {
        border-color: var(--status-refounded);
        color: var(--status-refounded);
      }
      &[data-status="expired"] > span {
        border-color: var(--status-expired);
        color: var(--status-expired);
      }
    }
    span {
      display: block;
      font-weight: 400;
      margin-right: 5px;
    }
  }
  &__select {
    @include flex(flex-start, 10px, center);
    width: 100%;
    margin-bottom: 20px;
    flex-direction: column;
  }
  &__option {
    @include text(1.6rem, 700, 1.8rem);
    position: relative;
    display: block;
    width: 100%;
    padding: 5px;
    background-color: var(--primary-gray);
    color: var(--primary-bg);
    border-radius: 5px;
    z-index: 0;
    &:hover {
      cursor: pointer;
      & > span {
        &::before {
          border: 2px dashed var(--primary-green);
        }
      }
    }
    &::before {
      position: absolute;
      content: "";
      width: 18px;
      height: 18px;
      background-color: var(--primary-bg);
      transform: translateY(-50%);
      border-radius: 50%;
      top: 50%;
      left: 5px;
      z-index: 0;
    }
    & > span {
      &::before {
        position: absolute;
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 14px;
        content: "";
        z-index: 0;
      }
    }
  }
  &__radio {
    display: inline-block;
    visibility: hidden;
    width: 18px;
    margin-right: 5px;
    &:checked {
      & + span {
        &::before {
          background-color: var(--primary-green);
        }
      }
    }
  }
  &__actions {
    @include flex(center, 10px, center);
    width: 100%;
  }
  &__cancel {
    @include btn-secondary;
  }
  &__submit {
    @include btn-primary;
  }
}