@import "../Mixins.scss";

.kpis {
  &__title {
    @include subtitle();
  }
  &__summary {
    @include flex(center);
    width: 100%;
    margin: 30px auto;
    border-radius: 27px;
    flex-wrap: wrap;
    gap: 10px;
  }
  &__item {
    flex: 1;
    min-width: 120px;
    background-color: var(--table-cell-bg);
    border-radius: 10px;
    text-align: center;
  }
  &__name {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 20px;
    text-transform: uppercase;
  }
  &__data {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 33px;
    text-align: center;
  }
}

@import "./responsive/Kpis";