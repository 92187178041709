@import "./Mixins.scss";

$dropdown-icon: url("../assets/images/icons/chevron-down.svg");

.compliance-user {
  width: 100%;
  padding: 25px 35px;
  background-color: var(--primary-bg);
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  &__header {
    @include flex(space-between);
    width: 100%;
  }
  &__title,
  &__subtitle {
    @include green-title();
    margin: 0;
  }
  &__subtitle {
    width: 100%;
    padding: 20px;
    font-weight: normal;
  }
  &__no-data {
    @include flex(center);
    width: 100%;
    min-height: 60px;
    padding: 0 16px;
    text-transform: uppercase;
    color: var(--text-color);
    background-color: var(--dropdown-table-row-bg);
    border-radius: 5px;
  }
  &__button {
    @include primary-button();
    @include flex(center);
    &--chose {
      margin-bottom: 10px;
    }
    &--cancel {
      padding: 5px 40px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__button-icon {
    width: 20px;
    height: 20px;
    margin-right: 15px;
    object-fit: contain;
  }
  &__section {
    width: 100%;
    margin: 40px 0;
    background-color: var(--compliance-user-bg);
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    &[open] .compliance-user__dropdown{
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      &::after {
        transform: rotateX(180deg);
      }
    }
  }
  &__grid {
    @include grid(repeat(9, 1fr), 20px);
    padding: 0 20px 50px;
  }
  &__label {
    position: relative;
    width: 100%;
    height: 100%;
    &--maxwidth {
      max-width: 260px;
    }
    &::before {
      position: absolute;
      display: block;
      content: attr(data-text);
      font-weight: 600;
      font-size: 1rem;
      line-height: 14px;
      background-color: var(--compliance-user-bg);
      padding: 5px;
      color: var(--secondary-text-color);
      top: -30%;
      left: 10px;
    }
    &:nth-of-type(1) {
      grid-area: 1 / 1 / 2 / 3;
    }
    &:nth-of-type(2) {
      grid-area: 1 / 3 / 2 / 5;
    }
    &:nth-of-type(3) {
      grid-area: 1 / 5 / 2 / 7;
    }
    &:nth-of-type(5) {
      grid-area: 1 / 8 / 2 / 10;
    }
    &:nth-of-type(6) {
      grid-area: 2 / 1 / 3 / 3;
    }
    &:nth-of-type(8) {
      grid-area: 2 / 4 / 3 / 6;
    }
    &:nth-of-type(9) {
      grid-area: 2 / 6 / 3 / 8;
    }
    &:nth-of-type(10) {
      grid-area: 2 / 8 / 3 / 10;
    }
  }
  &__input {
    width: 100%;
    min-height: 40px;
    padding: 0 10px;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 19px;
    border: 1px solid #DADADA;
    border-radius: 4px;
    color: var(--text-color);
    &:read-only {
      color: #D1D1D1;
    }
    &--upload {
      display: none;
    }
  }
  &__dropdown {
    position: relative;
    width: 100%;
    padding: 20px 60px 20px 20px;
    background-color: var(--dropdown-bg);
    border-radius: 14px;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 2rem;
    text-transform: uppercase;
    color: var(--secondary-text-color);
    list-style: none;
    &:hover {
      cursor: pointer;
    }
    &::after {
      position: absolute;
      display: block;
      content: "";
      width: 20px;
      height: 20px;
      background-image: $dropdown-icon;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      top: 20px;
      right: 20px;
      bottom: 20px;
      transition: transform .5s ease;
      transform: 0deg;
    }
  }
  &__dropdown-content {
    width: 100%;
    padding: 40px;
  }
  &__dropdown-title {
    width: 100%;
    margin: 25px 0;
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
    text-transform: uppercase;
    color: var(--dropdown-text-color);
  }
  &__dropdown-table {
    .rdt_Table {
      background-color: var(--compliance-user-bg);
      & > div {
        background-color: transparent;
      }
    }
    .rdt_TableRow {
      min-height: 70px;
      background-color: var(--dropdown-table-row-bg);
      border-radius: 4px;
      margin-bottom: 20px;
      &:not(:last-of-type) {
        border: 0;
        border-bottom: 0;
      }
    }
    .rdt_TableCol {
      font-weight: 600;
      font-size: 1.1rem;
      line-height: 15px;
      text-align: center;
      text-transform: uppercase;
      color: var(--dropdown-text-color);
    }
    .rdt_TableCell {
      font-weight: 300;
      font-size: 1.2rem;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
      color: var(--dropdown-table-color);
    }
    .rdt_TableHeadRow {
      border: 0;
      background-color: var(--compliance-user-bg);
    }
    .rdt_TableCol_Sortable {
      color: var(--dropdown-text-color);
      &:not(:focus),
      &:hover {
        color: var(--dropdown-text-color);
      }
    }
    .rdt_Pagination {
      background-color: transparent;
      color: var(--text-color);
      border-top: none;
    }
  }
  &__dropdown-table-status {
    @include flex(center);
    padding: 5px 10px;
    border-radius: 5px;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 16px;
    margin: 0;
    &::before {
      display: block;
      content: "";
      width: 10px;
      height: 10px;
      margin-right: 8px;
      border-radius: 10px;
    }
    &--pending {
      color: #F3BB1C;
      border: 1px solid var(--dropdown-status-pending);
      &::before {
        background: #F3BB1C;
      }
    }
    &--default {
      color: #a09e9a;
      border: 1px solid var(--dropdown-status-default);
      &::before {
        background: #a09e9a;
      }
    }
    &--rejected {
      color: #F03738;
      border: 1px solid var(--dropdown-status-rejected);
      &::before {
        background: #F03738;
      }
    }
    &--approved {
      color: #3CC13B;
      border: 1px solid var(--dropdown-status-approved);
      &::before {
        background: #3CC13B;
      }
    }
  }
  &__dropdown-table-actions {
    @include grid(repeat(4, 40px), 15px);
  }
  &__dropdown-table-action {
    @include primary-button();
    width: 100%;
    height: 100%;
    max-height: 40px;
    padding: 10px;
    border-radius: 100%;
    &--first {
      grid-area: 1 / 1 / 2 / 2;
    }
    &--second {
      grid-area: 1 / 2 / 2 / 3;
    }
    &--third {
      grid-area: 1 / 3 / 2 / 4;
    }
    &--four {
      grid-area: 1 / 4 / 2 / 5;
    }
  }
  &__dropdown-table-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__choose-file-container {
    @include flex(space-between);
    // flex-wrap: wrap;
    width: 100%;
  }
  &__choose-file {
    @include flex(flex-start);
    flex-wrap: wrap;
    width: 100%;
  }
  &__choose-file-text,
  &__choose-file-name {
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 14px;
    text-align: center;
    color: var(--text-color);
    margin: 0;
  }
  &__choose-file-name {
    font-weight: 400;
    font-size: 1.2rem;
    margin: 15px 0;
  }
  &__choose-file-preview {
    place-self: center;
    min-width: 60px;
    width: 60px;
    margin: 15px 0;
    object-fit: contain;
  }
  &__choose-file-input {
    @include flex(center);
    flex-direction: column;
    width: 100%;
    max-width: 350px;
    min-height: 180px;
    margin-right: 20px;
    background-color: var(--dropdown-input);
    font-weight: 600;
    font-size: 1rem;
    line-height: 14px;
    text-align: center;
    color: var(--secondary-text-color);
    border: 2px dashed #DADADA;
    border-radius: 4px;
    &--hover {
      position: relative;
      border: 0;
      &::before {
        position: absolute;
        display: block;
        content: "";
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        background-color: var(--dropdown-input-hover);
        border: 2px dashed var(--dropdown-input-hover);
        border-radius: 4px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
  @include media (xxl) {
    padding: 25px 30px;
    &__dropdown-content {
      padding: 30px;
    }
    &__choose-file-container {
      flex-direction: column;
    }
    &__choose-file {
      justify-content: space-between;
      margin-bottom: 40px;
    }
    // &__choose-file-text,
    // &__choose-file-name {
    //   font-weight: 600;
    //   font-size: 1.4rem;
    //   line-height: 14px;
    //   text-align: center;
    //   color: var(--text-color);
    //   margin: 0;
    // }
    // &__choose-file-name {
    //   font-weight: 400;
    //   font-size: 1.2rem;
    //   margin: 15px 0;
    // }
    // &__choose-file-preview {
    //   place-self: center;
    //   min-width: 60px;
    //   width: 60px;
    //   margin: 15px 0;
    //   object-fit: contain;
    // }
    &__choose-file-input {
      margin: 0;
    }
  }
  @include media (xl) {
    margin-bottom: 100px;
  }
  @include media (lg) {
    padding: 25px;
    &__dropdown-content {
      padding: 20px;
    }
  }
  @include media (md) {
    &__grid {
      grid-template-columns: 1fr 1fr;
    }
    &__label {
      &--maxwidth {
        max-width: 350px;
      }
      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3),
      &:nth-of-type(5),
      &:nth-of-type(6),
      &:nth-of-type(8),
      &:nth-of-type(9),
      &:nth-of-type(10) {
        grid-area: initial;
      }
    }
    &__choose-file {
      flex-direction: column;
      margin-bottom: 30px;
    }
    &__choose-file-input {
      margin-bottom: 30px;
    }
  }
  @include media (sm) {
    padding: 20px;
    &__header {
      flex-direction: column;
    }
    &__title {
      margin-bottom: 20px;
    }
    &__section {
      margin: 30px 0;
    }
    &__grid {
      padding: 0 16px 30px;
    }
    &__choose-file,
    &__choose-file-input {
      margin-bottom: 25px;
    }
  }
  @include media (xs) {
    padding: 20px 16px;
    &__grid {
      grid-template-columns: 1fr;
    }
    &__dropdown-content {
      padding: 16px;
    }
    &__dropdown {
      padding: 16px 60px 16px 16px;
    }
  }
}