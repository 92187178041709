@import "../Mixins.scss";

.login {
  @include flex(center);
  width: 100%;
  min-height: 100vh;
  &__video {
    position: fixed;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
  &__background {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 16.15%,
      rgba(27, 27, 27, 0.5) 100%,
      rgba(27, 27, 27, 0.5) 100%
    );
    left: 0;
    bottom: 0;
    z-index: 1;
  }
  &__container {
    @include blue-gradient();
    position: relative;
    width: 98%;
    max-width: 650px;
    padding: 50px 20px;
    text-align: center;
    z-index: 1;
  }
  &__logo {
    display: block;
    max-width: 180px;
    width: 60%;
    height: 180px;
    margin: auto;
    object-fit: contain;
    color: #FFFFFF;
  }
  &__form {
    max-width: 300px;
    margin: auto;
  }
  &__label {
    width: 100%;
    margin-bottom: 40px;
    color: #ffffff;
    text-align: left;
    font-size: 1.4rem;
    &--pin {
      @extend .login__label;
      text-align: center;
      margin-bottom: 15px;
    }
    &--recovery {
      @extend .login__label;
      text-align: center;
      color: rgb(50, 228, 228);
      margin-bottom: 15px;
    }
    &--password {
      @extend .login__label;
      position: relative;
    }
  }
  &__input {
    width: 100%;
    border: 1px solid #ffffff;
    height: 35px;
    border-radius: 22px;
    padding: 0 10px;
    margin-top: 5px;
    font-weight: 300;
    font-size: 1.4rem;
    line-height: 1.9rem;
    color: #FFFFFF;
    caret-color: #FFFFFF;
    &:-webkit-autofill {
      color: #FFFFFF;
      -webkit-text-fill-color: #FFFFFF;
    }
    &--pin {
      @extend .login__input;
      display: block;
      width: 110px;
      margin: 5px auto 0;
      text-align: center;
      font-size: 1.8rem;
    }
    &--recovery {
      @extend .login__input;
      text-align: center;
      font-size: 1.3rem;
    }
  }
  &__password {
    position: absolute;
    width: 20px;
    height: 20px;
    padding: 0;
    right: 10px;
    bottom: 8px;
  }
  &__eye {
    width: 100%;
    height: 100%;
    object-fit: contain;
    color: #FFFFFF;
  }
  &__recovery {
    color: #FFFFFF;
    margin-bottom: 30px;
    &:active {
      transform: translateY(0);
    }
  }
  &__submit {
    background: rgba(45, 115, 125, 0.65);
    border-radius: 9px;
    font-weight: 800;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #ffffff;
    padding: 15px 60px;
  }
}

@import "./responsive/Login";