.welcome {
  @include media(xl) {
    grid-template-columns: 1fr 2fr;
    &__boxes {
      width: 100%;
    }
  }
  @include media(lg) {
    &__boxes {
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
    }
  }
  @include media(md) {
    grid-template-columns: 1fr;
    &__user {
      border-radius: 0;
      border-bottom-left-radius: 46px;
      border-bottom-right-radius: 46px;
      padding: 10px 0 20px;
    }
    &__language,
    &__logout,
    &__time,
    &__logo,
    &__settings {
      display: none;
    }
    &__logo {
      height: 90px;
    }
    &__mobile {
      @include flex(space-between);
      width: 95%;
      margin: 10px auto 20px;
    }
    &__mobile-menu {
      display: flex;
    }
    &__logo,
    &__time {
      &--mobile {
        display: block;
      }
    }
    &__logo {
      &--mobile {
        width: 40%;
        max-width: 90px;
        margin-bottom: 0;
      }
    }
    &__hour {
      &--mobile {
        margin: 0;
        font-size: 2rem;
      }
    }
    &__day {
      &--mobile {
        font-size: 0.8rem;
        margin: 0;
      }
    }
  }
  @include media(sm) {
    &__picture {
      height: 100px;
      width: 100px;
    }
    &__boxes {
      grid-template-columns: 1fr 1fr;
    }
    &__mobile-menu {
      height: 50px;
    }
    &__mobile-link {
      width: 20px;
      height: 20px;
      &:last-child {
        width: 28px;
        height: 28px;
      }
    }
  }
}