@import "../Mixins.scss";

.language {
  $root: &;
  @include flex(center);
  position: relative;
  display: inline-block;
  margin: 40px 0;
  &--mobile {
    margin: 0;
    #{$root}__selector {
      padding: 0;
      background-image: none;
      &:active {
        transform: translateY(0);
      }
    }
    #{$root}__icon {
      width: 24px;
      height: 24px;
      margin: 0;
      filter: brightness(0) invert(1);
    }
  }
  &__icon {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
  }
  &__selector {
    @include flex(center);
    color: #ffffff;
    font-size: 1.4rem;
    background-image: url("../../assets/images/icons/dropdown-blue.svg");
    background-position: right;
    background-repeat: no-repeat;
    padding: 10px 20px 10px 0px;
    color: var(--primary-gray);
  }
  &__options {
    background-color: #163545;
    position: absolute;
    top: 35px;
    left: 20px;
    border-radius: 7px;
  }
  &__button {
    display: block;
    font-size: 1.2rem;
    color: #ffffff;
    padding: 10px 20px;
    min-width: 85px;
    &:hover {
      background-color: #245d68;
    }
    &:first-child {
      &:hover {
        border-radius: 7px 7px 0 0;
      }
    }
    &:last-child {
      &:hover {
        border-radius: 0 0 7px 7px;
      }
    }
  }
}

@import "./responsive/Language";