.header {
  @include media(xl) {
    &__title {
      margin-top: 70px;
    }
    &__actions,
    &__logo-link {
      display: none;
    }
    &__mobile {
      display: flex;
    }
  }
  @include media(sm) {
    &__title {
      font-size: 3rem;
    }
  }
}