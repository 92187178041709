@import "./Mixins.scss";
/**/

.status {
	border: 1px solid transparent;
	border-radius: 0.5em;
	width: 100%;
	text-align: center;
	padding: 1px 0.3em;
	&--pending {
		border-color: var(--status-pending);
		color: var(--status-pending);
	}
	&--approved {
		border-color: var(--status-approved);
		color: var(--status-approved);
	}
	&--rejected {
		border-color: var(--status-rejected);
		color: var(--status-rejected);
	}
	&--refounded {
		border-color: var(--status-refounded);
		color: var(--status-refounded);
	}
	&--expired {
		border-color: var(--status-expired);
		color: var(--status-expired);
	}
}
.paysys {
	&__business {
		@include flex(flex-end);
		grid-gap: 20px;
		flex-wrap: wrap;
	}
	&__business-action {
		width: 120px;
		height: 35px;
		max-height: 35px;
		padding: 4px 20px;
		background-color: var(--paysys-button);
		border-radius: 3px;
		&[data-active="true"] {
			background-color: var(--paysys-button-active);
		}
	}
	&__business-action-image {
		width: 100%;
		max-height: 25px;
		object-fit: contain;
	}
	&__business-action-text {
		font-size: 1.4rem;
		font-weight: normal;
		line-height: 1.6rem;
		text-transform: uppercase;
		margin: 0;
		color: #FFFFFF;
	}
	@include media(sm) {
		&__business {
			justify-content: center;
		}
	}
}