@import "../Mixins.scss";

.menu-link {
  $root: &;
  @include flex(flex-start);
  flex-direction: column;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  &[data-small="true"] {
    &::before,
    &::after {
      width: 92px;
      height: 92px;
    }
    #{$root}__icon {
      width: 100px;
      height: 100px;
      padding: 32px;
    }
    #{$root}__title {
      &::before {
        width: 68px;
        height: 68px;
      }
    }
  }
  &[data-sidebar="true"] {
    flex-direction: row;
    &::before,
    &::after {
      width: 34px;
      height: 34px;
      border-width: 1px;
      transition: transform .7s ease-out;
      top: 0;
      left: 0;
    }
    #{$root}__icon {
      width: 36px;
      height: 36px;
      padding: 10px;
      margin: 0 18px 0 0;
    }
    #{$root}__title {
      font-size: 1.1rem;
      letter-spacing: .12em;
      &::before {
        width: 26px;
        height: 26px;
        border-color: rgba(38, 223, 214, .5);
        border-right-color: transparent;
        transform: rotate(-45deg);
        top: 4px;
        left: 4px;
      }
    }
  }
  &--unactive {
    filter: grayscale(1);
    pointer-events: none;
    display: none;
  }
  &::before,
  &::after {
    position: absolute;
    width: 122px;
    height: 122px;
    content: "";
    border-radius: 50%;
    border: 4px solid #26DFD6;
    border-right-color: transparent;
    border-top-color: transparent;
    transform: rotate(-45deg);
    transition: transform 1s ease-out;
    top: 0px;
    z-index: 1;
  }
  &::before {
    border-top-color: #26DFD6;
  }
  &:hover {
    &::after {
      transform: rotate(-140deg);
    }
  }
  &__icon {
    width: 130px;
    height: 130px;
    padding: 35px;
    object-fit: contain;
  }
  &__title {
    color: #ffffff;
    font-size: 1.6rem;
    text-align: center;
    letter-spacing: 0.2em;
    &::before {
      position: absolute;
      width: 98px;
      height: 98px;
      content: "";
      border-radius: 50%;
      border: 1px solid rgba(38, 223, 214, .75);
      border-right-color: transparent;
      transform: translateX(-50%) rotate(-45deg);
      top: 15px;
      left: 50%;
      z-index: 1;
    }
  }
}

@import "./responsive/MenuLink";