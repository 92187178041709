@import "../Mixins.scss";

$icon-delete: url("../../assets/images/icons/icon-country-delete.svg");

.psp-configuration {
  $root: &;
  width: 100%;
  min-width: 600px;
  margin-bottom: 20px;
  background-color: var(--psp-configuration-option);
  border: 1px solid var(--psp-configuration-border);
  border-radius: 14px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  &[open] {
    #{$root}__summary {
      border-radius: 14px 14px 0 0;
    }
  }
  &[data-configurable="false"] {
    pointer-events: none;
    border: 1px solid transparent;
    box-shadow: none;
    opacity: .5;
  }
  &__summary {
    @include grid(1fr 1fr 3fr 1fr, 15px);
    width: 100%;
    padding: 12px 15px;
    background-color: var(--psp-configuration-summary);
    border-radius: 14px;
    list-style: none;
    &:hover {
      cursor: pointer;
    }
  }
  &__content {
    width: 100%;
    padding: 0 15px;
  }
  &__name,
  &__status,
  &__countries,
  &__country,
  &__amount,
  &__settings-countries {
    place-self: center left;
    max-height: fit-content;
    margin: 0;
    color: var(--psp-configuration-name);
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 16px;
    text-transform: uppercase;
    opacity: .5;
  }
  &__name {
    opacity: 1;
  }
  &__status {
    position: relative;
    margin-left: 15px;
    &[data-status="true"] {
      &::before {
        background-color: #3CC13B;
      }
    }
    &::before {
      position: absolute;
      width: 11px;
      height: 11px;
      content: "";
      background-color: #F03738;
      border-radius: 50%;
      top: 2px;
      left: -15px;
    }
  }
  &__countries {}
  &__country {
    opacity: 1;
  }
  &__settings {
    @include grid(55px 1fr 3fr 1fr, 0 15px);
    width: 100%;
    padding: 15px 0;
    border-top: 1px solid var(--psp-configuration-border);
  }
  &__switch {
    width: 55px;
    height: 28px;
    padding: 0;
    background-color: transparent;
    border: 1px solid var(--toggle-button-border-off);
    border-radius: 16px;
    transition: background-color .5s ease;
    will-change: background-color;
    &[data-active="true"] {
      background-color: var(--toggle-button);
      border-color: var(--toggle-button-border);
      #{$root}__switch-circle {
        transform: translateX(26px);
        background-color: var(--toggle-button-circle);
      }
    }
    &:active {
      transform: translateY(0)
    }
  }
  &__switch-circle {
    display: block;
    width: 24px;
    height: 24px;
    margin-left: 2px;
    border-radius: 50%;
    background-color: var(--toggle-button-circle-off);
    transition: transform .5s ease;
    will-change: transform;
  }
  &__settings-countries {
    @include flex(flex-start);
    flex-wrap: wrap;
    opacity: 1;
  }
  &__settings-country {
    position: relative;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 6px 25px 6px 12px;
    color: #5A5A5A;
    background-color: #EAEAEA;
    border: 1px solid #5A5A5A;
    border-radius: 18px;
    &::after {
      @include center-bg();
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      background-size: contain;
      background-image: $icon-delete;
      top: 9px;
      right: 8px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__settings-label {
    position: relative;
    width: 100%;
    height: 40px;
    border: 1px solid var(--psp-configuration-label-border);
    border-radius: 5px;
    &::before {
      position: absolute;
      padding: 3px 5px;
      content: attr(data-text);
      color: var(--psp-configuration-label-text);
      background-color: var(--psp-configuration-label);
      font-weight: 600;
      font-size: 9px;
      line-height: 12px;
      top: -9px;
      left: 6px;
    }
  }
  &__settings-input {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: var(--psp-configuration-input);
  }
  &__settings-separator {
    grid-area: 2 / 2 / 3 / 4;
    width: 100%;
    height: 1px;
    background: #E6E6E6;
    margin: 10px auto 22px;
    display: block;
  }
}

@import "./responsive/PspConfiguration";